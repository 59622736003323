import styles from './Header.module.css'
import logoPolus from '../../../Images/logo-polus.webp';
import logoPolusColor from '../../../Images/logo_polus.webp';
import { Fade as Hamburger } from 'hamburger-react'
import { useState, useEffect } from "react";
import AdaptiveMenu from './AdaptiveMenu/AdaptiveMenu';
import { useLocation, useNavigate } from "react-router-dom";

function Header(props) {
    const [isOpen, setOpen] = useState(false)

    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.documentElement.style.setProperty('--ServiseElement-background', 'none');
        document.documentElement.style.setProperty('--aboutElement-background', 'none');
        document.documentElement.style.setProperty('--ProjectsElement-background', 'none');
        document.documentElement.style.setProperty('--POElement-background', 'none');
        document.documentElement.style.setProperty('--ProductsElement-background', 'none');
        document.documentElement.style.setProperty('--ContactsElement-background', 'none');
        document.documentElement.style.setProperty('--HeaderElement-color', '#FAFAFF');
        document.documentElement.style.setProperty('--HamburgerWrapper-color', '#FAFAFF');
        if (location.pathname.split('/')[1] === "projects") {
            document.documentElement.style.setProperty('--ProjectsElement-background', '#4A6FF5');
        } else if (location.pathname.split('/')[1] === "Программные продукты") {
            document.documentElement.style.setProperty('--POElement-background', '#4A6FF5');
        } else if (location.pathname.split('/')[1] === "products") {
            document.documentElement.style.setProperty('--ProductsElement-background', '#4A6FF5');
        } else if (location.pathname.split('/')[1] === "software_products") {
            document.documentElement.style.setProperty('--POElement-background', '#4A6FF5');
        } else if (location.pathname.split('/')[1] === "about") {
            document.documentElement.style.setProperty('--aboutElement-background', '#4A6FF5');
        } else if (location.pathname.split('/')[1] === "contact") {
            document.documentElement.style.setProperty('--ContactsElement-background', '#4A6FF5');
            document.documentElement.style.setProperty('--HeaderElement-color', 'black');
            document.documentElement.style.setProperty('--HamburgerWrapper-color', 'black');
        }
    }, [location.pathname.split('/')[1]]);

    return (
        <div className={styles.GlobalWrapper} >
            <div className={styles.AdaptiveMenu} >
                <AdaptiveMenu isOpen={isOpen} setOpen={setOpen} servicesRef={props.servicesRef} />
            </div>
            <div className={styles.HeaderWrapper}>
                <div className={styles.logoStyle} onClick={() => { navigate('/'); setOpen(false) }}>
                    <img className={styles.imgLogo} src={location.pathname.split('/')[1] === "contact" ? logoPolusColor : logoPolus} />
                </div>
                <div className={styles.HeaderElementWrapper} >
                    <div className={styles.HeaderElement + " " + styles.ServiseElement} onClick={() => {
                        if (location.pathname.split('/')[1] === "") {
                            props.servicesRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" })
                        } else {
                            navigate("/", {
                                state: {
                                    isService: true,
                                }
                            })
                        }

                    }}>
                        Услуги
                    </div>
                    <div className={styles.HeaderElement + " " + styles.ProjectsElement} onClick={() => navigate('/projects')}>
                        Проекты
                    </div>
                    <div className={styles.HeaderElement + " " + styles.POElement} onClick={() => navigate('/software_products')}>
                        Программные&nbsp;продукты
                    </div>
                    <div className={styles.HeaderElement + " " + styles.aboutElement} onClick={() => navigate('/about')}>
                        О&nbsp;нас
                    </div>
                    <div className={styles.HeaderElement + " " + styles.ContactsElement}
                        style={location.pathname.split('/')[1] === "contact" ? { color: "#FAFAFF" } : {}} onClick={() => navigate('/contact')}>
                        Контакты
                    </div>
                </div>
                <div className={styles.burgerMargin} />
                <div className={styles.HamburgerWrapper}>
                    <Hamburger toggled={isOpen} toggle={setOpen} />
                </div>
            </div>
        </div>
    );
}


export default Header;
