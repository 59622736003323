import styles from './PageFooter.module.css'
import { useState } from "react";

function PageFooter() {
    const [isOpen, setIsOpen] = useState(
        false
    );
    const sendMail = () => {
        alert("Открытие КП")
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 300 }}>
            <div className={styles.ClientsWrapper}>
                <div style={{ marginTop: 0, padding: "10px" }}>
                    <div className={styles.feedbackWrapper} style={{ marginTop: 20 }}>
                        <div className={styles.smallText}>
                            <div className={styles.consistHeaderText}>
                                В состав используемых технических средств <span style={{ color: "#4A6FF5" }}>должны входить:</span>
                            </div>
                            <div className={styles.consistElementText}>
                                <li>IBM PC совместимый с процессором архитектуры 80386 и выше</li>
                                <li>ОЗУ <span style={{ color: "#4A6FF5" }}>более 2Гб</span></li>
                                <li>Наличие свободного места на жестком диске <span style={{ color: "#4A6FF5" }}>более 4Гб</span> для установки программы</li>
                                <li>Для работы программы (одного нескольких классов) <span style={{ color: "#4A6FF5" }}>не менее 100Гб</span></li>
                                <li>Windows 7 или новее</li>
                                <li>Поддержка OpenGL 3.3</li>
                            </div>
                        </div>
                        <div className={styles.spareElement}>
                            <svg width="30" height="100%" viewBox="0 0 30 100%" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25.9297L29.4338 0.929688L0.56625 0.929687L12.5 25.9297L17.5 25.9297ZM12.5 165.999C12.5 167.38 13.6193 168.499 15 168.499C16.3807 168.499 17.5 167.38 17.5 165.999L12.5 165.999ZM12.5 23.4297L12.5 165.999L17.5 165.999L17.5 23.4297L12.5 23.4297Z" fill="#4A6FF5" />
                            </svg>
                        </div>
                        <div className={styles.sendFormWrapper}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    Описание установочного процесса
                                </div>
                                <div style={{ flex: 1 }} />
                                <div style={{ cursor: 'pointer' }} onClick={() => setIsOpen(!isOpen)}>
                                    <svg width="10" height="40" viewBox="0 0 10 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5" cy="5" r="5" fill="#576982" /> <circle cx="5" cy="20" r="5" fill="#576982" /> <circle cx="5" cy="35" r="5" fill="#576982" /> </svg>
                                </div>
                            </div>
                            {!isOpen &&
                                <a style={{ textDecoration: 'none' }} href={window.location.href.split(window.location.pathname)[0] + '/oSVSXITmBHbWeyIqV8SeUD77piEhu2I5191DWilHNIU6iF90a8rGuMATMPRhXwaK.pdf'} target="_blank">
                                    <div className={styles.buttonDownload} style={{ marginTop: 23, textDecoration: 'none' }}>
                                        <div style={{ textDecoration: 'none' }}>Скачать</div>
                                    </div>
                                </a>
                            }

                            {isOpen &&
                                <div className={styles.buttonDownloadList} style={{ marginTop: 23 }}>
                                    <div className={styles.buttonDownloadListText} style={{ padding: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/oSVSXITmBHbWeyIqV8SeUD77piEhu2I5191DWilHNIU6iF90a8rGuMATMPRhXwaK.pdf'} target="_blank">Описание установочного процесса</a>
                                    </div>
                                    <div className={styles.buttonDownloadListText} style={{ paddingLeft: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/POwPYUiTtZQsKvkzlSXSp7fAGY35eWhhBvPpYs32DgLblkm0sdwdis3W2w3EQfot.pdf'} target="_blank">Свидетельство о государственной регистрации программы для ЭВМ</a>
                                    </div>
                                    <div className={styles.buttonDownloadListText} style={{ paddingLeft: 25, paddingTop: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/1CYcdBr9sToggsujY7Ewr8c0y2x0IXTESFZzPnfIQL7o1zvJauh4bduKZrDfL8QQ.pdf'} target="_blank">Отчёт о проведении апробации робота</a>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default PageFooter;
