import styles from './ProductBox.module.css'

const ProductBox = ({ title, subtext, bgcolor, bg, onClick }) => {
    return (
        <div className={styles.bgBox} style={{ backgroundImage: "url(" + bg + ")", backgroundColor: bgcolor }} onClick={() => {
            if (onClick) {
                onClick();
                window.scrollTo({
                    top: 0,
                    left: 0,
                });
            }
        }}>
            <div className={styles.bgTextHolder}>
                <div className={styles.bgTitle}>
                    {title}
                </div>
                <div className={styles.bgSubText}>
                    {subtext}
                </div>
            </div>
        </div>
    );
}


export default ProductBox;
