import ProjectDescription from './ProjectDescription/ProjectDescription';
import ProjectContainer from './ProjectsContainer/ProjectContainer';
import styles from './ProjectsPage.module.css'

function ProjectsPage() {
    return (
        <div>
           <ProjectDescription/>
           <ProjectContainer/>
        </div>
    );
}


export default ProjectsPage;
