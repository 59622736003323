import styles from './Content.module.css'

function Content() {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={styles.ProjectsWrapper}>
                    <div style={{ width: 1320, marginTop: 60, padding: "10px" }}>
                        <div className={styles.firstText}>
                            Интерактивная рабочая тетрадь по робототехнике, разработанная <span style={{ color: "#4A6FF5" }}>совместно с ООО "ФИЗИКОН"</span>.  Предназначена учителей и учащихся 10-11 классов образовательных учреждений, а также для ДОУ.
                        </div>
                        <div className={styles.secondText} style={{ marginTop: 60 }}>
                            Тетрадь представляет собой электронный тренажер, направленный на развитие широкого спектра кругозора и формирования у обучающихся инженерных навыков.<br /><br />
                            Рабочая тетрадь состоит из интерактивных заданий, проверка которых производится автоматически – компьютером. Курс является пропедевтическим для курса «Информатика» и «Физика», сопровождается изучением курса «Математика», способствует более глубокому пониманию изучаемых в школьном цикле дисциплин, а также пониманию их последующего применения.<br /><br />
                            В курсе помимо робототехники освещаются вопросы, связанные с основами материаловедения, это способствует более глубокому пониманию робототехнических конструкций.<br /><br />
                            Курс согласуется с инженерной направленностью обучения, которое базируется на новых информационных технологиях, что способствует развитию информационной культуры и взаимодействию с миром научно-технического творчества. В частности, в курсе рассмотрены основы алгоритмизации и системы графического программирования.
                        </div>
                        <div className={styles.thirdText} style={{ marginTop: 100 }}>
                            В ПО: <span style={{ color: "#4A6FF5" }}>42</span> параграфа и <span style={{ color: "#4A6FF5" }}>около 240</span> интерактивных заданий
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.CompanyDescriptionWrapper}>
                <div style={{ width: 1305, padding: "10px" }}>

                </div>
            </div>
        </div>
    );
}


export default Content;
