import styles from './Content.module.css'

function Content(props) {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={styles.ProjectsWrapper}>
                    <div style={{ width: 1320, marginTop: 60, padding: "10px" }}>
                        <div className={styles.firstText}>
                            <span style={{ color: "#4A6FF5" }}>Цель программы</span> – моделирование работы виртуального робота, который умеет двигаться и читать команды, задачи и другой текст и отображается на экране монитора компьютера или ноутбука, планшета, что решает некоторые <span style={{ color: "#4A6FF5" }}>задачи учебного процесса </span>школы для начальных классов.
                        </div>
                        <div className={styles.secondText} style={{ marginTop: 60 }}>
                            Программная логика виртуального робота, в отличие от традиционных приложений позволяет обеспечить взаимодействие с пользователем и адекватно реагировать на поставляемую информацию (голосовую).
                        </div>
                        <div className={styles.thirdText} style={{ marginTop: 100 }}>
                            Возможности программы:
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.CompanyDescriptionWrapper}>
                <div style={{ width: 1305, padding: "10px" }}>

                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 80 }}>
                <div className={styles.ProjectsWrapper} style={{ marginTop: -80 }}>
                    <div style={{ width: 1320, marginTop: 60, padding: "10px" }}>
                        <div className={styles.firstText}>
                            Программа <span style={{ color: "#4A6FF5" }}>«Виртуальный робот»</span> производит вызов программы стороннего разработчика GODOT, где <span style={{ color: "#4A6FF5" }}>реализован образ</span> виртуального робота и обеспечивается его движение в пределах, не отвлекающих внимание, но обеспечивающих <span style={{ color: "#4A6FF5" }}>эффект присутствия.</span>
                        </div>
                        <div className={styles.secondText} style={{ marginTop: 60 }}>
                            Во внешней программе реализованы функции для учителя и ученика, вход по паролю и логину, файловая база выбора учебных заданий, система разработки учебных заданий на скриптовом языке, специально для этого разработанным в проекте, система оценки времени выполнения заданий. <br /> <br />
                            Программа разработана для 64 разрядных систем, для того, чтобы покрыть круг компьютеров разного времени производства и тем самым увеличить возможность применения программы «Виртуальный робот».<br /> <br /><br /> <br />
                        </div>
                        <div className={styles.firstText}>
                            В разработке использовался язык разметки <span style={{ color: "#4A6FF5" }}>html</span>, вызов функций осуществляет благодаря модулям написанным на <span style={{ color: "#4A6FF5" }}>JavaScript и PHP</span><br /> <br />
                        </div>
                        <div className={styles.secondText}>
                            В последней версии программы:<br /><br />
                            <li>Улучшена приспособленность программы к разным версиям компьютеров</li>
                            <li>Адаптирована голосовая система воспроизведения текста (text-to-speech)</li>
                            <li>Движения робота сделаны менее явными</li>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 130 }}>
                <div className={styles.buyWrapper} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={styles.lineWrapper}>
                        <div>
                            <div className={styles.textLicense} style={{ marginTop: 21 }}>Стоимость полной лицензионной программы:<br /></div>
                            <div className={styles.priceTextLicense} style={{ marginBottom: 21, marginTop: 10 }}>10 000 рублей</div>
                        </div>
                        <div style={{ flex: 1 }} />
                        <div>
                            <div className={styles.buyWrapperButton}  onClick={()=>props.setIsOpen(true)}>
                                <div>Купить</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Content;
