import AchievementBlock from './AchievementBlock/AchievementBlock';
import styles from './CompanyDescription.module.css'
import Header from './../../CommonComponents/Header/Header';

function CompanyDescription() {
    return (
        <div style={{display:'flex', justifyContent:'center'}}>
            <div className={styles.CompanyDescriptionWrapper}>
                <div style={{ width: 1305, padding: "10px" }}>

                    <div className={styles.HeaderText} style={{ marginTop: 220 }}>
                        ООО НЦ «Полюс» — это постоянные разработки в сфере науки и образования
                    </div>
                    <div className={styles.SmallText} style={{ marginTop: 50 }}>
                        Каждый год мы разрабатываем множество IT технологий и успешно продвигаем их на российском рынке. Основанная в 2002 году, завоевавшая доверие множества организаций.<br /><br />
                        Мы четырежды поддержаны ФСИ в научно-технической сфере:<br />МОСТ II  2015-16 гг. Развитие НТИ IV 2018-2020гг.<br />Акселерация ИИ 2022-2023гг. Внедрение ИИ 2023-2026 гг.
                    </div>
                    <div className={styles.AdvantagesWrapper} style={{ marginTop: 60 }}>
                        <div className={styles.AdvantagesBox}>
                            <AchievementBlock header={"20+ лет"} text={"Успешной\nработы"} />
                            <AchievementBlock header={">15"} text={"Закрытых\nпроектов"} />
                        </div>
                        <div className={styles.AdvantagesBox}>
                            <AchievementBlock header={"50+"} text={"Заслуженных\nнаград"} />
                            <AchievementBlock header={"20+"} text={"Компаний\nпартнёров"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default CompanyDescription;
