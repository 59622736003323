import { useState } from 'react';
import ContactBox from './ContactBox/ContactBox';
import styles from './ContactContainer.module.css'
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

function ContactContainer() {
    const [isFirstOpen, setFirstOpen] = useState(true)
    const [isSecondOpen, setSecondOpen] = useState(false)
    const [isSupport, setIsSupport] = useState(false)
    const [email, setEmail] = useState("");
    const [supportText, setSupportText] = useState("");
    const [isBlock, setIsBlock] = useState(false)
    let navigate = useNavigate();
    const location = useLocation();
    const onKeyDown = e => {
        if (e.key === 'Enter')
            sendMail()
    }

    const sendMail = () => {
        if (isBlock) {
            alert("Идёт отправка...")
            return
        }
        if (supportText === "") {
            alert("Введите текст")
            return
        }
        if (email === "") {
            alert("Введите почту")
            return
        }
        setIsBlock(true)
        const request = {
            "Name": "Вопрос технической поддержки",
            "Email": email,
            "Phone": "",
            "Organization": supportText,
            "Product": "-",
            "Subscribe": false
        };
        axios.post("https://tkpoluslab.ru:8081/api/v1/offers/send", request)
            .then(res => {
                if (res.status === 200) {
                    setIsBlock(false)
                    alert("Запрос успешно отправлен")
                    setEmail("")
                    setSupportText("")
                }
            }).catch((error) => {
                alert("Ошибка отправки")
                setIsBlock(false)
            });
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--eMailBoxWrapper-width', '0px');
        document.documentElement.style.setProperty('--eMailBoxWrapper-right', '-10px');
    }, [location.pathname.split('/')[1]]);


    return (
        <div className={styles.contactJustifier}>
            <div className={styles.contactContainerWrapper}>
                <div className={styles.contactHolder}>
                    <div className={styles.contactBoxes}>
                        <div>
                            <ContactBox title={"Главный офис компании ООО \"НЦ \"Полюс\""} isOpen={isFirstOpen} setIsOpen={setFirstOpen}>
                                <div className={styles.contactTextBox}>
                                    <div className={styles.contactText}>
                                        634029
                                    </div>
                                    <div className={styles.contactText}>
                                        г.Томск, ул. Белинского, д. 18 <br className={styles.noBr} />
                                        (Часы работы Пн.-Пт. с 9.00 - 18.00)
                                    </div>
                                    <div className={styles.contactBold}>
                                        +7 (3822) 528-999 <br className={styles.noBr} />
                                        +7 (3822) 529-333<br />
                                    </div>
                                    <div className={styles.contactText}>
                                        Почта для связи: polus@tkpolus.ru
                                    </div>
                                </div>
                            </ContactBox>
                            <ContactBox title={"Офис компании ООО \"НЦ \"Полюс\" в Москве"} isOpen={isSecondOpen} setIsOpen={setSecondOpen}>
                                <div className={styles.contactTextBox}>
                                    <div className={styles.contactText}>
                                        109029
                                    </div>
                                    <div className={styles.contactText}>
                                        г. Москва, ул. Скотопрогонная, 27/26с1 <br className={styles.noBr} />(Часы работы Пн.-Пт. с 9.00 - 18.00)


                                    </div>
                                    <div className={styles.contactBold}>
                                    +7 901 057 2457 <br className={styles.noBr} />
                                  
                                    </div>
                                    <div className={styles.contactText}>
                                        Почта для связи: msk@tkpolus.ru
                                    </div>
                                </div>
                            </ContactBox>
                            <ContactBox title={"Техническая поддержка"} isOpen={isSupport} setIsOpen={setIsSupport} isSupport={true}>
                                <div className={styles.contactTextBox}>
                                    <div className={styles.contactSupportText}>
                                        Куда можно обратиться по техническим вопросам?<br /><br />
                                        - В форме ниже или в нашем чате в телеграме <a href="https://t.me/ncpolus_techsupport" target="_blank"><span style={{ color: "#4A6FF5", textDecorationLine: 'underline', cursor: 'pointer' }}>НЦ Полюс | Техническая поддержка</span></a>
                                    </div>
                                    <div>
                                        <textarea value={supportText}
                                            onChange={(e) => setSupportText(e.target.value)}
                                            placeholder={'Напишите нам - мы ответим на ваши вопросы' + '\n' + 'Свяжемся с Вами по почте в ближайшее время'}
                                            className={styles.messageQuestions} rows="1" cols="45" name="text"></textarea>
                                    </div>
                                    <div className={styles.eMailBoxWrapper}>
                                        <div>
                                            <input
                                                value={email}
                                                className={styles.emailInputStyle}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder={"Ваш Email"}
                                                onKeyDown={onKeyDown}
                                            />
                                        </div>
                                        <div className={styles.buttonSendMail} style={{ marginTop: 5 }} onClick={() => sendMail()}>
                                            Связаться с нами
                                        </div>
                                    </div>
                                </div>
                            </ContactBox>
                        </div>
                    </div>
                    <div className={styles.contactSidePanel} style={{ marginTop: 40 }}>
                        <div className={styles.contactSideText}>
                            ООО «НЦ «Полюс»<br /><br />
                            Юридический адресс 634029, г. Томск, <br />
                            ул. Белинского, д. 18
                        </div>
                        <div className={styles.contactSideText}>
                            ОГРН 1027000908439<br /><br />
                            ИНН 7017062946<br /><br />
                            КПП 701701001
                        </div>
                        <div className={styles.contactSideText}>
                            Поддержка:<br /><br />
                            support@tkpolus.ru
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ContactContainer;
