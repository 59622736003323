import ProductBox from './ProductBox/ProductBox';
import styles from './ProductContainer.module.css'
import bg from '../../../Images/labs_img.webp'
import wb14placeholder from '../../../Images/wb14placeholder.webp'
import wb79placeholder from '../../../Images/wb79placeholder.webp'
import wb1011placeholder from '../../../Images/wb1011placeholder.webp'
import virPlaceholder from '../../../Images/virPlaceholder.webp'
import acapph from '../../../Images/acapph.webp'
import { useLocation, useNavigate } from "react-router-dom";


function ProductContainer() {
    let navigate = useNavigate();
    return (
        <div className={styles.projectJustifier}>
            <div className={styles.projectContainerWrapper}>
                <div className={styles.projectHolder}>
                    <ProductBox
                        title="Облако знаний: Рабочие тетради."
                        subtext="Робототехника 1-4 классы"
                        bgcolor="#548FF933"
                        bg={wb14placeholder}
                        onClick={() => navigate('/software_products/workbooks_1_4')}
                    />
                    <ProductBox
                        title="Облако знаний: Рабочие тетради."
                        subtext="Робототехника 7-9 классы"
                        bgcolor="#548FF933"
                        bg={wb79placeholder}
                        onClick={() => navigate('/software_products/workbooks_7_9')}
                    />
                    <ProductBox
                        title="Облако знаний: Рабочие тетради."
                        subtext="Робототехника 10-11 классы"
                        bgcolor="#548FF933"
                        bg={wb1011placeholder}
                        onClick={() => navigate('/software_products/workbooks_10_11')}
                    />
                    <ProductBox
                        title="виртуальный робот"
                        subtext="VIR (VIRTUAL ROBOT)"
                        bgcolor="#548FF933"
                        bg={virPlaceholder}
                        onClick={() => navigate('/software_products/virtual_robot')}
                    />
                    <ProductBox
                        title="Программа анализа каллиграфического написания алфавита"
                        subtext="«ACAP» (Alphabet caligraph analysis program)"
                        bgcolor="#548FF933"
                        bg={acapph}
                        onClick={() => navigate('/software_products/acap')}
                    />
                </div>
            </div>
        </div>
    );
}


export default ProductContainer;
