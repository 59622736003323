import styles from './Projects.module.css'
import arrow from '../../../Images/link.webp';
import { useNavigate } from "react-router-dom";

function Projects() {
    let navigate = useNavigate();
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={styles.ProjectsWrapper}>
                <div style={{ width: 1320, marginTop: 60, padding: "10px" }}>
                    <div className={styles.HeaderText}>
                        Наши проекты
                    </div>
                    <div className={styles.blockWrapper}>
                        <div className={styles.meowWrapper} onClick={()=>{navigate("/projects/robo_logo");  window.scrollTo(0, 0);}}> 
                            <div className={styles.ArrowWrapper}>
                                <img src={arrow} />
                            </div>
                            <div style={{ height: "100%", position: 'relative' }}>
                                <div style={{ padding: 40, position: 'absolute', bottom: 100 }}>
                                    <div className={styles.HeaderProjectText}>
                                        Программно-аппаратный комплекс логопедической поддержки «Платформа речевого развития РобоЛого»
                                    </div>
                                    <div className={styles.SmallProjectText} style={{ marginTop: 22 }}>
                                        для детей дошкольного и младшего школьного возраста с использованием технологий ИИ
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.rideSideProject} style={{ marginLeft: 60 }}>
                            <div className={styles.mobitestWrapper}  onClick={()=>{navigate("/projects/mobitest");  window.scrollTo(0, 0);}}>
                                <div className={styles.ArrowWrapper2}>
                                    <img src={arrow} />
                                </div>
                                <div style={{ height: "100%", position: 'relative' }}>
                                    <div style={{ padding: 40, position: 'absolute', bottom: 80 }}>
                                        <div className={styles.HeaderProjectText} style={{ width: 490 }}>
                                            Обновленное приложение MobiTEST для проведения онлайн уроков и лабораторных работ (В разработке)
                                        </div>
                                        <div className={styles.SmallProjectText} style={{ marginTop: 22, width: 375 }}>
                                            в котором учителя сами могут опубликовать свои курсы и просматривать работы других авторов
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.sdrWrapper} style={{ marginTop: 40 }} onClick={()=>{navigate("/projects/voice_dialogue_system");  window.scrollTo(0, 0);}}>
                                <div className={styles.ArrowWrapper2}>
                                    <img src={arrow} />
                                </div>
                                <div style={{ paddingLeft: 40, paddingTop: 10 }}>
                                    <div className={styles.HeaderProjectText} style={{ width: 490 }}>
                                        Программное обеспечение системы речевого диалога (СРД)
                                    </div>
                                    <div className={styles.SmallProjectText} style={{ marginTop: 22, width: 375 }}>
                                        В конце 2022 года был успешно закрыт проект по заказной разработке для компании ООО "Прикладная Робототехника" на 20+ млн. рублей.
                                    </div>
                                </div>
                            </div>
                            <div className={styles.buttonWrapper} style={{ marginTop: 51 }} onClick={() => { navigate("/projects"); window.scrollTo(0, 0); }}>
                                Смотреть еще
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Projects;
