import Content from './Content/Content';
import HeaderText from './HeaderText/HeaderText';
import styles from './Workbooks79.module.css'
import { useState } from "react";
import PageFooter from './../Workbooks14/PageFooter/PageFooter';
import CommercialOffer from './../../../CommonComponents/CommercialOffer/CommercialOffer';

function Workbooks79() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div >
            <CommercialOffer isOpen={isOpen} setIsOpen={setIsOpen} startProduct="Тетрадь. Робототехника 7-9 классы" />
            <HeaderText />
            <Content />
            <PageFooter setIsOpen={setIsOpen} />
        </div>
    );
}


export default Workbooks79;
