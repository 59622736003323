import ProjectDescription from './ProductDescription/ProductDescription';
import ProductContainer from './ProductsContainer/ProductContainer';
import styles from './ProductsPage.module.css'

function ProductsPage() {
    return (
        <div>
           <ProjectDescription/>
           <ProductContainer/>
        </div>
    );
}


export default ProductsPage;
