import styles from './CommercialOffer.module.css'
import { useState } from "react";
import axios from "axios";
import CommercialOfferBigSize from './CommercialOfferBigSize/CommercialOfferBigSize';
import CommercialOfferSmallSize from './CommercialOfferSmallSize/CommercialOfferSmallSize';

function CommercialOffer(props) {
    const [isUsl, setIsUsl] = useState(false);
    const [isObr, setIsObr] = useState(true);
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [phone, setPhone] = useState("");
    const [organization, setOrganization] = useState("");
    const [product, setProduct] = useState(props.startProduct);
    const [isBlock, setIsBlock] = useState(false)

    const sendHandler = () => {
        if (isBlock) {
            alert("Идёт отправка...")
            return
        }
        if (name === "") {
            alert("Введите ваше имя")
            return
        }
        if (mail === "") {
            alert("Введите вашу почту")
            return
        }
        if (phone === "") {
            alert("Укажите ваш номер телефона")
            return
        }
        if (!phone.match(/(?:\+|\d)[\d\-\(\) ]{9,}\d/gi)) {
            alert("Неправильный номер")
            return
        }
        if (organization === "") {
            alert("Укажите вашу организацию")
            return
        }
        if (product === "" || !product) {
            alert("Укажите интересующий вас продукт")
            return
        }
        if (!isUsl) {
            alert("Вы должны быть согласны с условиями обработки персональных данных")
            return
        }
        setIsBlock(true)
        const request = {
            "Name": name,
            "Email": mail,
            "Phone": phone,
            "Organization": organization,
            "Product": product,
            "Subscribe": isObr
        };
        axios.post("https://tkpoluslab.ru:8081/api/v1/offers/send", request)
            .then(res => {
                if (res.status === 200) {
                    // alertHandler("Заявка успешно отправлена");
                    props.setIsOpen(false)
                    setIsBlock(false)
                    alert("Запрос успешно отправлен")
                    setName("")
                    setMail("")
                    setPhone("")
                    setOrganization("")
                    setIsObr(true)
                    setIsUsl(false)
                }
            }).catch((error) => {
                alert("Ошибка отправки")
                setIsBlock(false)
            });
    }

    return (
        <div>
            {props.isOpen &&
                <div className={styles.blackbg} onClick={() => props.setIsOpen(false)}>
                    <div onClick={(e) => e.stopPropagation()}>
                        <div className={styles.bigSize}>
                            <CommercialOfferBigSize
                                isUsl={isUsl} setIsUsl={setIsUsl} isObr={isObr} setIsObr={setIsObr} name={name} setName={setName}
                                mail={mail} setMail={setMail} phone={phone} setPhone={setPhone} organization={organization}
                                setOrganization={setOrganization} product={product} setProduct={setProduct} sendHandler={sendHandler} />
                        </div>
                        <div className={styles.smallSize}>
                            <CommercialOfferSmallSize
                                isUsl={isUsl} setIsUsl={setIsUsl} isObr={isObr} setIsObr={setIsObr} name={name} setName={setName}
                                mail={mail} setMail={setMail} phone={phone} setPhone={setPhone} organization={organization}
                                setOrganization={setOrganization} product={product} setProduct={setProduct} sendHandler={sendHandler} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}


export default CommercialOffer;
