import styles from './HeaderText.module.css'

function HeaderText() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={styles.CompanyDescriptionWrapper}>
                <div style={{ width: 1305, padding: "10px" }}>

                    <div className={styles.HeaderText} style={{ marginTop: 170 }}>
                        Программа анализа каллиграфического написания алфавита «ACAP» (Alphabet caligraph analysis program)
                    </div>
                    <div className={styles.SmallText} style={{ marginTop: 50 }}>
                        Программа разработана для проверки правописания учеников с помощью работы нейронной сети.<br/>
                        Внесена в российский реестр программного обеспечения (реестровая запись №22417 от 14.05.2024)
                    </div>
                    <div className={styles.AdvantagesWrapper} style={{ marginTop: 60 }}>

                    </div>
                </div>
            </div>
        </div>
    );
}


export default HeaderText;
