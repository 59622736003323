import styles from './ContactBox.module.css'
import line from '../../../../Images/contact_line.svg'
import plus from '../../../../Images/plus.svg'
import { useState, useEffect } from "react";
const ContactBox = ({ title, children, isOpen, setIsOpen, isSupport }) => {
    
    return (
        <div style={{ position: 'relative' }}>
            <div className={styles.contactTitleHolder}>
                <div className={styles.contactTitle}>
                    <div className={styles.onlyTitle}>
                        {title}
                    </div>
                    <img className={styles.plus} style={isOpen ? { transform: 'rotate(45deg)' } : {}} src={plus} onClick={() => {
                        if (isSupport) {
                            if (isOpen) {
                                document.documentElement.style.setProperty('--eMailBoxWrapper-width', '0px');
                                document.documentElement.style.setProperty('--eMailBoxWrapper-right', '-10px');
                                setTimeout(() => setIsOpen(!isOpen), 300);
                            } else {
                                setTimeout(() => {
                                    document.documentElement.style.setProperty('--eMailBoxWrapper-width', '312px');
                                    document.documentElement.style.setProperty('--eMailBoxWrapper-right', '-300px');
                                }, 300);
                                setIsOpen(!isOpen);
                            }
                        } else {
                            setIsOpen(!isOpen);
                        }


                    }} />
                </div>
                <img style={{ userSelect: 'none' }} src={line} />
                <div className={styles.contactContent} style={isOpen ? (isSupport?{height:470}:{}) : { height: '0px' }}>
                    {children}
                </div>
            </div>
        </div>

    );
}


export default ContactBox;
