import styles from './AboutHeader.module.css'

function AboutHeader() {

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={styles.CompanyDescriptionWrapper}>
                <div style={{ width: 1305, padding: "10px" }}>

                    <div className={styles.HeaderText} style={{ marginTop: 180 }}>
                        Компания «Научный центр «Полюс» - системный интегратор и разработчик ИТ-решений в сфере образования.
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AboutHeader;    
