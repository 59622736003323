import styles from './ContactDescription.module.css'

function ContactDescription() {
    return (
        <div className={styles.contactJustifier}>
            <div className={styles.contactDescWrapper}>
                <div className={styles.topTextBlock}>
                    <div className={styles.topTextTitle}>
                        Контакты
                    </div>
                    <div className={styles.topTextSubTitle}>
                        Мы всегда на связи!
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ContactDescription;
