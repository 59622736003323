import styles from './AboutNews.module.css'

function AboutNews() {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30, marginBottom: 160 }}>
            <div style={{ width: 1305 }}>
                <div className={styles.text}>Где можно получить новости, связанные с компанией? - <a href="https://t.me/Polusnews" target="_blank"><span className={styles.underlinedText}>В нашем новостном телеграм канале</span></a></div>
            </div>
        </div>
    );
}

export default AboutNews;    
