import styles from './Content.module.css'

function Content() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={styles.ProjectsWrapper}>
                <div className={styles.catWrapper}>
                </div>
                <div>
                    <div className={styles.BlockTextWrapper}>
                        <div className={styles.svgWrapper}>
                            <svg width="30" height="193" viewBox="0 0 30 193" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25L29.4338 6.31186e-07L0.56625 -6.31185e-07L12.5 25L17.5 25ZM12.5 190C12.5 191.381 13.6193 192.5 15 192.5C16.3807 192.5 17.5 191.381 17.5 190L12.5 190ZM12.5 22.5L12.5 190L17.5 190L17.5 22.5L12.5 22.5Z" fill="#CED6F0" />
                            </svg>
                        </div>
                        <div className={styles.FSIwrapper} style={{ marginBottom: 80 }}>
                            <div>
                                <div className={styles.HeaderText} style={{ maxWidth: 860 }}>Разрабатывается обновленное приложение MobiTEST для проведения онлайн уроков, викторин и лабораторных работ.</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.BlockTextWrapper}>
                        <div className={styles.svgWrapper}>
                            <svg width="30" height="195" viewBox="0 0 30 195" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25L29.4338 6.31186e-07L0.56625 -6.31185e-07L12.5 25L17.5 25ZM12.5 192C12.5 193.381 13.6193 194.5 15 194.5C16.3807 194.5 17.5 193.381 17.5 192L12.5 192ZM12.5 22.5L12.5 192L17.5 192L17.5 22.5L12.5 22.5Z" fill="#9FB2F3" />
                            </svg>

                        </div>
                        <div className={styles.HeaderText}>Проект получил своё развитие в виде образовательного облачного сервиса. Мы <span style={{ color: "#4A6FF5" }}>разработали специализированный образовательный сервис</span> для проведения онлайн уроков, викторин и лабораторных работ.</div>
                        <br />
                        <div className={styles.SmallText}>Пользователями являются преподаватели от начальных школ до университета. </div>
                    </div>
                    <div className={styles.BlockTextWrapper}>
                        <div className={styles.svgWrapper}>
                            <svg width="30" height="218" viewBox="0 0 30 218" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25L29.4338 6.31186e-07L0.56625 -6.31185e-07L12.5 25L17.5 25ZM12.5 215C12.5 216.381 13.6193 217.5 15 217.5C16.3807 217.5 17.5 216.381 17.5 215L12.5 215ZM12.5 22.5L12.5 215L17.5 215L17.5 22.5L12.5 22.5Z" fill="#8A9FEB" />
                            </svg>
                        </div>
                        <div className={styles.HeaderText}>  В данном сервисе <span style={{ color: "#4A6FF5" }}>доступен редактор</span> для всех учителей по созданию собственных учебных курсов, чтобы каждый мог <span style={{ color: "#4A6FF5" }}>подстроить образовательную программу</span> под уникальные особенности своего класса. </div>
                        <div className={styles.MediumText} style={{ marginTop: 30 }}>Благодаря встроенному разделу "викторины", существует возможность создавать опросы, тесты различной длины  и проверять знание в прямом эфире с самых разных устройств.</div>
                    </div>
                    <div className={styles.BlockTextWrapper} style={{ marginBottom: 180 }}>
                        <div className={styles.svgWrapper}>
                            <svg width="30" height="331" viewBox="0 0 30 331" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25L29.4338 6.31186e-07L0.56625 -6.31185e-07L12.5 25L17.5 25ZM12.5 328C12.5 329.381 13.6193 330.5 15 330.5C16.3807 330.5 17.5 329.381 17.5 328L12.5 328ZM12.5 22.5L12.5 328L17.5 328L17.5 22.5L12.5 22.5Z" fill="#4A6FF5" />
                            </svg>
                        </div>
                        <div className={styles.HeaderText}>Также присутствуют официальные курсы от компании, разработанные ведущими специалистами томской области по <span style={{ color: "#4A6FF5" }}>самым современным ФГОС.</span></div>
                        <br />
                        <div className={styles.HeaderText}>Данный образовательный сервис <span style={{ color: "#4A6FF5" }}>поспособствует планомерную улучшению качества образования</span> в российской федерации и сделает качественного образование более доступным.</div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Content;
