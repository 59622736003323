import styles from './About.module.css'
import AboutHeader from './AboutHeader/AboutHeader';
import SuccessStory from './SuccessStory/SuccessStory';
import Certificates from './Certificates/Certificates';
import ContactSocials from './../ContactsPage/ContactSocials/ContactSocials';
import AboutNews from './AboutNews/AboutNews';

function About() {

    return (
        <div>
            <AboutHeader />
            <SuccessStory />
            <Certificates />
            {/* <ContactSocials /> */}
            <AboutNews />
        </div>

    );
}

export default About;    
