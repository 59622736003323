import styles from './CommercialOfferSmallSize.module.css'

function CommercialOfferSmallSize(props) {
    return (
        <div className={styles.commercialOfferWrapper}>
            <div className={styles.leftSide}>
                <div style={{ marginLeft: 13, marginTop: 17, marginRight: 13 }}>
                    <div className={styles.headerText}>
                        Получить КП на продукты компании ООО НЦ “Полюс”
                    </div>
                    <div style={{ marginTop: 25 }}>
                        <input
                            value={props.name}
                            className={styles.inputName}
                            onChange={(e) => props.setName(e.target.value)}
                            placeholder={"Введите имя..."}
                        />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <input
                            value={props.mail}
                            className={styles.inputName}
                            onChange={(e) => props.setMail(e.target.value)}
                            placeholder={"Ваш Email..."}
                        />
                    </div>
                    <div className={styles.header2Text} style={{ marginTop: 14 }}>
                        Телефон
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <input
                            type="tel"
                            id="phone"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            value={props.phone}
                            className={styles.inputName}
                            onChange={(e) => props.setPhone(e.target.value)}
                            placeholder={"+7"}
                        />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <input
                            value={props.organization}
                            className={styles.inputName}
                            onChange={(e) => props.setOrganization(e.target.value)}
                            placeholder={"Организация"}
                        />
                    </div>
                    <div className={styles.header2Text} style={{ marginTop: 30, color: "#4A6FF5", "font-weight": 600 }}>
                        Интересуемый продукт
                    </div>
                    <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: 20, right: 20 }}>
                            <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 0.5L8.5 8.5L1 0.5" stroke="#0F0F28" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <select className={styles.selectName}
                            value={props.product}
                            onChange={e => props.setProduct(e.target.value)} >
                            <option className={styles.selectNameElement}>Виртуальный робот</option>
                            <option className={styles.selectNameElement}>Каллиграфическое написание</option>
                            <option className={styles.selectNameElement}>Тетрадь. Робототехника 1-4 классы</option>
                            <option className={styles.selectNameElement}>Тетрадь. Робототехника 7-9 классы</option>
                            <option className={styles.selectNameElement}>Тетрадь. Робототехника 10-11 классы</option>
                        </select>
                    </div>
                    <div style={{ display: 'flex', marginTop: 35 }}>
                        {!props.isUsl &&
                            <div style={{ marginRight: 15, cursor: 'pointer', marginTop: 4 }} onClick={() => props.setIsUsl(true)}>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="16" height="16" rx="4.5" stroke="#D8D8D8" />
                                </svg>
                            </div>
                        }
                        {props.isUsl &&
                            <div style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => props.setIsUsl(false)}>
                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="4.5" width="16" height="16" rx="4.5" stroke="#4A6FF5" />
                                    <path d="M2.70197 9.83484L8.1887 17.4027L20.851 1.88172" stroke="#4A6FF5" stroke-width="2" stroke-linecap="round" />
                                </svg>
                            </div>
                        }

                        <div className={styles.smallText}>
                            Согласен с условиями обработки персональных данных
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: 11 }}>
                        {!props.isObr &&
                            <div style={{ marginRight: 15, cursor: 'pointer', marginTop: 4 }} onClick={() => props.setIsObr(true)}>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="16" height="16" rx="4.5" stroke="#D8D8D8" />
                                </svg>
                            </div>
                        }
                        {props.isObr &&
                            <div style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => props.setIsObr(false)}>
                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="4.5" width="16" height="16" rx="4.5" stroke="#4A6FF5" />
                                    <path d="M2.70197 9.83484L8.1887 17.4027L20.851 1.88172" stroke="#4A6FF5" stroke-width="2" stroke-linecap="round" />
                                </svg>
                            </div>
                        }
                        <div className={styles.smallText} >
                            Согласие на получение новостных рассылок ООО НЦ “Полюс”
                        </div>
                    </div>
                    <div className={styles.buttonSend} onClick={()=>props.sendHandler()}>
                        Отправить заявку
                    </div>
                </div>
            </div>
        </div>
    );
}


export default CommercialOfferSmallSize;
