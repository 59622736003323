import PageFooter from '../Workbooks14/PageFooter/PageFooter';
import Content from './Content/Content';
import HeaderText from './HeaderText/HeaderText';
import styles from './Workbooks1011.module.css'
import { useState } from "react";
import CommercialOffer from './../../../CommonComponents/CommercialOffer/CommercialOffer';

function Workbooks1011() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div >
            <CommercialOffer isOpen={isOpen} setIsOpen={setIsOpen} startProduct="Тетрадь. Робототехника 10-11 классы" />
            <HeaderText />
            <Content />
            <PageFooter setIsOpen={setIsOpen} />
        </div>
    );
}

export default Workbooks1011;
